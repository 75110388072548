interface Gamehandler {
    addToCollection: Function,
    resetCollection: Function,
    countCollection: Function,
    getCollectionArray: Function
}

export default <Gamehandler>{
    addToCollection: (objectId: string): void => {
        let alreadyFoundObjects: string|null = localStorage.getItem('collection')
        if (alreadyFoundObjects === null) {
            localStorage.setItem('collection', objectId)
        } else {
            localStorage.setItem('collection', alreadyFoundObjects += `,${objectId}`)
        }
    },
    resetCollection: (): void => {
        const collection = localStorage.getItem('collection')
        if(collection !== null){
            localStorage.removeItem('collection')
        }
    },
    countCollection: (): number => {
        const collection = localStorage.getItem('collection')
        if (collection !== null) {
            return collection.split(',').length
        }else{
            return 0
        }
    },
    getCollectionArray: (): Array<string> => {
        let alreadyFoundObjects: string|null = localStorage.getItem('collection')
        if (alreadyFoundObjects !== null) {
            return alreadyFoundObjects.split(',')
        }else{
            return []
        }
    }
}