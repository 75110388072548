import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import gamehandler from "@/services/gamehandler";
import "@/assets/styles/main.scss"

// @ts-ignore
import { StoryblokVue, apiPlugin } from '@storyblok/vue';

const GAME = createApp(App)
GAME.use(StoryblokVue, {
    accessToken: process.env.VUE_APP_API_TOKEN,
    use: [apiPlugin],
})
GAME.use(store)
GAME.use(router)
GAME.provide('gamehandler',gamehandler)
GAME.mount('#app')